<!--项目画像项目 xwh 20211009-->
<template>
  <div class="base-container">
    <div class="main-wrapper">
      <div v-if="isShow">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">基本信息</span>
            </div>
            <div>
                <div class="card-list">
                    <div class="small-item">
                        <p>小区地址：<span style="color:#185BCE;cursor:pointer;" @click="toMap()">{{ portraitData.address||'-' }}</span></p>
                        <p>产权描述：<span>{{ portraitData.propertyDesc||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>所属区域：<span>{{ portraitData.region||'-' }}</span></p>
                        <p>物业类别：<span>{{ portraitData.propertyCategory||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>开发商：<span>{{ portraitData.constructionCompany||'-' }}</span></p>
                        <p>物业公司：<span>{{ portraitData.propertyCompany||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list">
                    <div class="small-item">
                        <p>建筑类别：<span>{{ portraitData.constructionType||'-' }}</span></p>
                        <p>装修状况：<span>{{ portraitData.decorationCondition||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>楼栋总数：<span>{{ portraitData.buildingCount||'-' }}</span></p>
                        <p>物业费：<span>{{ portraitData.propertyCost||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>楼层状况：<span>{{ portraitData.floorType||'-' }}</span></p>
                        <p>物业费描述：<span>{{ portraitData.propertyCostDesc||'-' }}</span></p>
                    </div>
                </div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>建筑面积：<span>{{ portraitData.buildingArea||'-' }}</span></p>
                        <p>容积率：<span>{{ portraitData.plotRate||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>占地面积：<span>{{ portraitData.landArea||'-' }}</span></p>
                        <p>项目特色：
                            <span class="bg-span" v-for="(item,index) in projectFeature" :key="index">{{ item }}</span>
                        </p>
                    </div>
                    <div class="small-item">
                        <p>绿化率：<span>{{ portraitData.greeningRate||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">配套设施</span>
            </div>
            <div>
                <div class="card-list no-border">
                    <div class="small-item">
                        <p>供暖：<span>{{ portraitData.heating||'-' }}</span></p>
                        <p>安全管理：<span>{{ portraitData.security||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>卫生服务：<span>{{ portraitData.healthService||'-' }}</span></p>
                        <p>停车位：<span>{{ portraitData.parkingCount||'-' }}</span></p>
                    </div>
                    <div class="small-item">
                        <p>通讯设备：<span>{{ portraitData.communicationEquipment||'-' }}</span></p>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
            <span class="card-title">销售信息</span>
            </div>
            <div>
            <table class="info-table">
                <tr>
                    <th>售楼电话</th>
                    <td>{{ portraitData.inqueryPhoneNumber||'-' }}</td>
                    <th>开盘时间</th>
                    <td>{{ portraitData.saleDate||'-' }}</td>
                </tr>
                <tr>
                    <th>售楼地址</th>
                    <td>{{ portraitData.saleAddress||'-' }}</td>
                    <th>交房时间</th>
                    <td>{{ portraitData.deliverDate||'-' }}</td>
                </tr>
                <tr>
                    <th>销售状态</th>
                    <td>{{ portraitData.saleStatus||'-' }}</td>
                    <th>主力户型</th>
                    <td>{{ portraitData.mainHouseStructure||'-' }}</td>
                </tr>
            
            </table>
            </div>
        </el-card>
      </div>
      
      <el-card v-if="isTagShow" class="box-card">
        <div slot="header" class="clearfix">
          <span class="card-title">项目标签</span>
        </div>
        <div>
           <table class="info-table label">
               <tr>
                   <th>标签类型</th>
                   <th style="text-align:left">标签明细</th>
               </tr>
               <tr>
                   <td>其他信息</td>
                   <td class="other-info">
                       <span>{{fromData.affordableHouse}}</span>
                       <span>{{fromData.stage}}</span>
                       <span>{{fromData.buildingStoreyNumber}}</span>
                       <span>{{fromData.actualGrossMargin}}</span>
                       <span>{{fromData.modelOrSaleOffice}}</span>
                   </td>
               </tr>
               <tr>
                   <td>拆迁补偿信息</td>
                   <td class="cq-info">
                       <span>{{fromData.rateOfLandAndDemolitionCost}}</span>
                       <span>{{fromData.compensationForDemolition}}</span>
                   </td>
               </tr>
              <tr>
                   <td>开发成本信息</td>
                   <td class="cb-info">
                       <span>{{fromData.mainConstructionPeriod}}</span>
                   
                   </td>
               </tr>
               <tr>
                   <td>房源占比</td>
                   <td class="fy-info">
                       <span>{{fromData.rateOfOrdinaryResidential}}</span>
                       <span>{{fromData.rateOfUnownedHouse}}</span>
                       <span>{{fromData.rateOfShopSale}}</span>
                       <span>{{fromData.rateOfBusiness}}</span>
                       <span>{{fromData.rateOfNonordinaryResidential}}</span>
                   </td>
               </tr>
               <tr>
                   <td>土地信息</td>
                   <td class="td-info">
                       <span>{{fromData.landPrice}}</span>
                       <span>{{fromData.landAcquisitionDate}}</span>
                   </td>
               </tr>
               <tr>
                   <td>销售信息</td>
                   <td class="xs-info">
                       <span v-if="fromData.majorSalesyear">{{fromData.majorSalesyear}}</span>
                       <span v-if="fromData.priceOfOrdinaryResidential">{{fromData.priceOfOrdinaryResidential}}</span>
                       <span v-if="fromData.priceOfNonordinaryResidential">{{fromData.priceOfNonordinaryResidential}}</span>
                       <span v-if="fromData.priceOfOtherCategories">{{fromData.priceOfOtherCategories}}</span>
                       <span v-if="fromData.projectCost">{{fromData.projectCost}}</span>
                       <span v-if="fromData.projectCost">{{fromData.priceOfBusiness}}</span>
                   </td>
               </tr>
          
           </table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
        portraitData:{},
        projectFeature:[],
        fromData: {},
        isShow: false,
        isTagShow: true
    };
  },
  mounted() {
    //调用数据接口
    this.getPortraitData();
    //获取标签数据
    this.getFromData();

    if(getLocalStore('projectForm')){
        if(getLocalStore('projectForm')=='own'){
            this.isTagShow = false;
        }else{
            this.isTagShow = true;
        }
    }
  },
  created(){
      this.getReport(0,'','');
  },
  destroyed(){
      this.getReport(1,'','');
  },
  methods: {
    ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
    //展现点击上报
    getReport(recordType,modelName,clickName){
        this.axios.request({
            method: "post", 
            url: '/dms/saveUserBehaviorRecord',
            data: {
                pageName:'portraitProject',
                userName:getLocalStore('areainfo').userName,
                recordType:recordType,
                modelName:modelName,
                clickName:clickName,
                userId:getLocalStore('areainfo').id,
                ipAddress:getLocalStore('areainfo').ipAddress,
                userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
    },
    //查询项目画像数据
    getPortraitData() {
        //测试使用固定id
        let projectId = getStore("thisProject").id;
        let projectName = getStore("thisProject").proName;
        this.axios({
            method: "get",
            url: "/dms/projectInfo",
            params: {
                // projectId: '988823',
                projectId: projectId,
                projectName:projectName
            },
        }).then((res) => {
                if(res.data.data){
                    this.isShow = true;
                }else{
                    return;
                }
                //存取项目画像数据
                this.portraitData = res.data.data;
                //拆分项目特色数据
                if(this.portraitData.projectFeature){
                    this.projectFeature = this.portraitData.projectFeature.split('|');
                }else{
                    this.projectFeature = ['-']
                }
            }).catch((error) => {
                console.log(error);
            });
    },
    //查询项目标签数据
    getFromData() {
        //测试使用固定id
        let projectId = getStore("thisProject").id;
        this.axios({
            method: "get",
            url: "/dms/projectInfoFromSystem",
            params: {
                // projectId: '988823'
                projectId: projectId
            },
        }).then((res) => {
                if(!res.data.data){
                    return;
                }
                this.fromData = res.data.data
              
            }).catch((error) => {
                console.log(error);
            });
    },
    toMap(){
        this.getReport(2,'information','addressBtn');
        let info = getStore('thisProject');
        if (info.latitude == '' && info.longitude == '') {
            this.$message.error('未检测到相应的项目坐标：该项目地址未上传')
            return
        }
        if (info.longitude > 70 && info.longitude < 135 && info.latitude > 20 && info.latitude < 55) {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            this.addPath("周边情况");
            this.addMenuList("/mapAround");
            setStore("currentMenu", "/mapAround");
            this.$router.push({path: "/mapAround"});

        } else {
            this.$message.error('未检测到相应的项目坐标：该项目填写的经纬度坐标不在中国版图范围内，请在项目信息中修改后再使用');
        }
    }

  },
};
</script>

<style scoped lang="less">
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both;
    }
    .box-card {
        width: 100%;
        border-radius: 2px;
        box-shadow: none;
        border: none;
    }
    .card-title{
        font-size: 16px;
    }
    .card-list{
        border-bottom: 1px solid #F2F3F5;
        padding-bottom: 8px;
        margin-bottom: 16px;
        overflow: hidden;
    }
    .small-item{
        width: 33.3%;
        float: left;
        >p{
            margin-bottom: 8px;
            color: #969799;
            >span{
                color: #646566;
            }
            .bg-span{
                background-color: rgb(243, 242, 245);
                margin-right: 16px;
            }
        }
    }
    .no-border{
        border: none;
        margin: 0;
    }
    .el-card{
        margin-bottom: 24px;
    }
    
    .info-table{
        width: 100%;
        border-collapse: collapse;
        th{
            background: #F6F6F6;
            border: 1px solid #EBEBEB;
            padding: 8px 12px;
            text-align: right;
            font-weight: 400;
            color: #333333;
        }
        td{
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            padding: 8px;
            padding-left: 12px;
            color: #555555;
        }
    }
    .label{
        td{
            text-align: center;
        }
        th{
            text-align: center;
        }
        tr td:nth-child(2){
            text-align: left;
            span{
                margin-right: 12px;
                font-size: 12px;
            }
        }
        tr th:nth-child(3){
            text-align: left;
        }
    }
    .other-info span{
        background: #EDF4FF;
        color: #185BCE;
    }
    .cq-info span{
        background: #FFF5ED;
        color: #ED6A0C;
    }
    .cb-info span{
        background: #F2EEFF;
        color: #A044C2;
    }
    .fy-info span{
        background: #F0FAF2;
        color: #2DA641;
    }
    .td-info span{
        background: #E2F1F8;
        color: #2692C4;
    }
    .xs-info span{
        background: #F2F3F5;
        color: #646566;
    }
</style>